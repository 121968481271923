






























































































import DropDown from '@/components/DropDownComponent.vue';
import PackageIcon from '@/components/PackageIconComponent.vue';
import TableSortArrowComponent from '@/components/TableSortArrowComponent.vue';
import Config from '@/config';
import { ISkyNodeClient } from '@/models/skynode/SkyNodeClient';
import { PackageStatusLabels } from '@/models/skynode/SkyNodePackageStatus';
import { Sorter } from '@/util/Sorter';
import { Component, Prop, Vue } from 'vue-property-decorator';
import TimeComponent from '@/components/TimeComponent.vue';
import { IPackageVersion } from '@/models/packages/PackageVersion';

@Component({
  components: {
    PackageIcon,
    DropDown,
    TableSortArrowComponent,
    TimeComponent
  }
})
export default class SkyNodeDetailPage extends Vue {
  private apiBaseUrl = '';
  public sorting: Sorter = new Sorter();

  @Prop({ default: () => [] })
  private node!: ISkyNodeClient;

  @Prop({ default: () => [] })
  private packageVersions!: IPackageVersion[];

  created() {
    this.apiBaseUrl = Config.skynode.skynodeApi;
  }

  //mock data
  private getMockSkynodeClientsPackages = [
    {
      name: 'Test 0',
      version: '1.0.1',
      downloadDate: '01/02/2021'
    },
    {
      name: 'Test 1',
      version: '2.0.2',
      downloadDate: '01/03/2021'
    },
    {
      name: 'Test 3',
      version: '3.0.3',
      downloadDate: '01/04/2021'
    }
  ];

  private sortByName() {
    this.sorting.sort((p) => p.name, this.node.packages, 'name');
  }

  private sortByVersion() {
    this.sorting.sortVersions(
      (p) => p.packageVersionId,
      this.node.packages,
      'version'
    );
  }

  private sortByInstallDate() {
    this.sorting.sort(
      (p) => {
        return p.downloadDate || undefined;
      },
      this.node.packages,
      'date'
    );
  }

  private sortByStatus() {
    this.sorting.sort(
      (p) => {
        if (!p.status) {
          return '';
        }
        return PackageStatusLabels.get(p.status);
      },
      this.node.packages,
      'status'
    );
  }

  private getPackageStatus(status: number) {
    return PackageStatusLabels.get(status);
  }

  private getPackageVersionById(id: number) : string {
    return this.packageVersions?.find(pv => pv.id === id)?.version ?? "unknown";
  }
}
