enum PackageStatusType {
  UNKNOWN,
  UP_TO_DATE,
  OUT_OF_DATE,
  UPDATING
}

const PackageStatusLabels = new Map<number, string>([
  [PackageStatusType.UNKNOWN, 'Unknown'],
  [PackageStatusType.UP_TO_DATE, 'Up to Date'],
  [PackageStatusType.OUT_OF_DATE, 'Out of Date'],
  [PackageStatusType.UPDATING, 'Updating']
]);

export { PackageStatusLabels, PackageStatusType };
