var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "container px-8 mx-auto h-4/5" }, [
    _c(
      "h1",
      { staticClass: "mt-6 mb-6" },
      [
        _c(
          "router-link",
          { attrs: { to: { name: "skynode.collection" } } },
          [
            _c("font-awesome-icon", {
              staticClass: "mx-2",
              attrs: { icon: ["fa", "arrow-left"] }
            }),
            _c("span", { staticClass: "my-6 text-2xl font-bold" }, [
              _vm._v("All Nodes")
            ])
          ],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "w-full mb-12 bg-white border border-black" }, [
      _c("div", { staticClass: "flex w-full pt-8 pb-20 bg-white" }, [
        _c("div", { staticClass: "flex-row w-1/2" }, [
          _c(
            "p",
            {
              staticClass: "pl-12 text-4xl skyhub-text",
              attrs: { id: "table-title" }
            },
            [_vm._v(" " + _vm._s(_vm.node.name) + " ")]
          ),
          _c("p", { staticClass: "pl-12 text-lg break-words skyhub-subtext" }, [
            _vm._v(" " + _vm._s(_vm.node.description) + " ")
          ])
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "mt-6 overflow-y-scroll border border-black h-3/5" },
      [
        _c(
          "table",
          {
            staticClass: "w-full skyhub-table",
            attrs: { "aria-describedby": "table-title" }
          },
          [
            _c("thead", { attrs: { id: "package-version-table" } }, [
              _c("tr", { staticClass: "text-left" }, [
                _c(
                  "th",
                  {
                    staticClass: "sticky top-0 px-8 py-2 bg-gray-50",
                    attrs: { scope: "col" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.sortByName()
                          }
                        }
                      },
                      [
                        _c("table-sort-arrow-component", {
                          attrs: { sorting: _vm.sorting, name: "name" }
                        }),
                        _vm._v(" Package Name ")
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass: "sticky top-0 px-8 py-2 bg-gray-50",
                    attrs: { scope: "col" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.sortByVersion()
                          }
                        }
                      },
                      [
                        _c("table-sort-arrow-component", {
                          attrs: { sorting: _vm.sorting, name: "version" }
                        }),
                        _vm._v(" Package Version ")
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass: "sticky top-0 px-8 py-2 bg-gray-50",
                    attrs: { scope: "col" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.sortByInstallDate()
                          }
                        }
                      },
                      [
                        _c("table-sort-arrow-component", {
                          attrs: { sorting: _vm.sorting, name: "date" }
                        }),
                        _vm._v(" Download Date ")
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass: "sticky top-0 px-8 py-2 bg-gray-50",
                    attrs: { scope: "col" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.sortByStatus()
                          }
                        }
                      },
                      [
                        _c("table-sort-arrow-component", {
                          attrs: { sorting: _vm.sorting, name: "status" }
                        }),
                        _vm._v(" Status ")
                      ],
                      1
                    )
                  ]
                )
              ])
            ]),
            _c(
              "tbody",
              { attrs: { id: "package-version-table" } },
              _vm._l(_vm.node.packages, function(pack, index) {
                return _c(
                  "tr",
                  { key: index, staticClass: "skyhub-table-row" },
                  [
                    _c("td", { staticClass: "px-3 py-3" }, [
                      _c("div", { staticClass: "flex items-center" }, [
                        _c("p", { staticClass: "p-1 px-5" }, [
                          _vm._v(" " + _vm._s(pack.name) + " ")
                        ])
                      ])
                    ]),
                    _c("td", { staticClass: "p-1 px-8" }, [
                      _c("div", { staticClass: "inline-block" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getPackageVersionById(pack.packageVersionId)
                            ) +
                            " "
                        )
                      ])
                    ]),
                    _c("td", { staticClass: "p-1 px-8" }, [
                      _c(
                        "div",
                        { staticClass: "inline-block" },
                        [
                          _c("time-component", {
                            attrs: { date: pack.downloadDate }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("td", { staticClass: "p-1 px-8" }, [
                      _c("div", { staticClass: "inline-block" }, [
                        _vm._v(
                          " " + _vm._s(_vm.getPackageStatus(pack.status)) + " "
                        )
                      ])
                    ])
                  ]
                )
              }),
              0
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }